import React from 'react'

const HeaderGeneric = (props) => (
    <a href="/">
        <header id="header">
            <h1>Kitchen Lab</h1>
            <p>machine learning &bull; genomics &bull; biomarker discovery</p>
        </header>
    </a>
)

export default HeaderGeneric

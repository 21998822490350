import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'

class Generic extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet title="KitchenLab &bull; Software" />
        <HeaderGeneric />
        <div id="main">
          <section id="content" className="main">
            <h2>Software</h2>
          </section>

          <section id="content2" className="main">
            <h3>exceRpt</h3>
            <p>
              The extra-cellular RNA processing toolkit (exceRpt) was designed
              to handle the variable contamination and often poor quality data
              obtained from low input smallRNA-seq samples such as those
              obtained from extra-cellular preparations. However the tool is
              perfectly capable of processing data from more standard
              cellular/tissue samples.
            </p>
            <p>
              <Link
                to="https://rkitchen.github.io/exceRpt"
                className="button"
                target="_blank"
              >
                view exceRpt on Github
              </Link>
            </p>
          </section>

          <section id="content3" className="main">
            <h3>EMpire</h3>
            <p>
              Deep integration of RNAseq and mass-spec proteomics data. Exploits
              transcript-level expression estimates from RNA-seq to set prior
              likelihoods and enable protein isoform abundances to be directly
              estimated from LC–MS/MS. The approach respects the observation
              that most genes appear to express a dominant isoform in a given
              cell type or tissue.
            </p>
            <p>
              <Link
                to="https://pubs.acs.org/doi/abs/10.1021/acs.jproteome.8b00310"
                className="button"
                target="_blank"
              >
                read the publication
              </Link>
            </p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic

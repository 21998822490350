import { Link } from 'gatsby'
import React from 'react'

const Footer = props => (
  <footer id="footer">
    <section>
      <h2>About us</h2>
      <p>
        The Kitchen Laboratory specialises in computational biology, functional
        genomics, and non-invasive diagnostics. We apply state-of-the-art
        statistical/machine-learning approaches and leverage the massive power
        and flexibility of cloud-computing to define novel biomarkers of
        cardiovascular disease, type 2 diabetes, neurodegenerative diseases, and
        various cancers.
      </p>
      <ul className="actions">
        <li>
          <Link to="/research" className="button">
            Learn More
          </Link>
        </li>
      </ul>
    </section>
    <section>
      <h2>Contact Info</h2>
      <dl className="alt">
        <dt>Address</dt>
        <dd>CVRC &bull; 149 13th St Charlestown, MA 02129 &bull; USA</dd>
        <dt>Phone</dt>
        <dd>(617) 643-3449</dd>
        <dt>Email</dt>
        <dd>
          <a href="mailto:rkitchen@mgh.harvard.edu">rkitchen@mgh.harvard.edu</a>
        </dd>
      </dl>
      <ul className="icons">
        <li>
          <a
            href="https://github.com/rkitchen"
            className="icon fa-github alt"
            target="_blank"
            rel="noreferrer"
          >
            <span className="label">GitHub</span>
          </a>
        </li>
        <li>
          <a
            href="https://cvrc.massgeneral.org/faculty/robert-kitchen-phd/"
            className="icon fa-dribbble alt"
            target="_blank"
            rel="noreferrer"
          >
            <span className="label">Dribbble</span>
          </a>
        </li>
        <li>
          <a
            href="http://uk.linkedin.com/in/rkitchen"
            className="icon fa-linkedin-square alt"
            target="_blank"
            rel="noreferrer"
          >
            <span className="label">LinkedIn</span>
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/RobKitchen"
            className="icon fa-twitter alt"
            target="_blank"
            rel="noreferrer"
          >
            <span className="label">Twitter</span>
          </a>
        </li>
        <li>
          <a
            href="https://scholar.google.com/citations?user=Fnrm6iwAAAAJ&hl=en"
            className="icon fa-google alt"
            target="_blank"
            rel="noreferrer"
          >
            <span className="label">Google Scholar</span>
          </a>
        </li>
        <li>
          <a
            href="https://connects.catalyst.harvard.edu/Profiles/profile/130338122"
            className="icon fa-book alt"
            target="_blank"
            rel="noreferrer"
          >
            <span className="label">Harvard Catalyst</span>
          </a>
        </li>
      </ul>
    </section>
    <p className="copyright">&copy; omics.kitchen 2019</p>
  </footer>
)

export default Footer
